import React from "react";
import styled from "styled-components";
import Layout from "../components/layout";
import { Container, Row, Col } from "react-bootstrap";
import { graphql } from "gatsby";
import PageHeader from "../components/pageHeader";
import TechnologyCard from "../components/Technology/technologyCard";
import Started from "../components/Layout/started";
import styles from "./technology.module.less";
import SEO from "../components/SEO";
import ScrollAnimation from "react-animate-on-scroll";

const Title = styled.span`
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0.03px;
  color: #242d41;
  margin-bottom: 15px;
  @media screen and (max-width: 1200px) {
    font-size: 26px;
  }
  @media screen and (max-width: 991px) {
    font-size: 24px;
  }
  @media screen and (max-width: 768px) {
    font-size: 22px;
    margin-bottom: 10px;
  }
  @media screen and (max-width: 576px) {
    font-size: 20px;
    br {
      display: none;
    }
  }
`;

const Text = styled.div`
  font-size: 16px;
  line-height: 1.88;
  letter-spacing: 0.02px;
  color: rgba(36, 45, 65, 0.7);
  padding: 20px 0;
  @media screen and (max-width: 576px) {
    font-size: 14px;
  }
`;

export default props => {
  return (
    <Layout>
    <SEO
      title="Technology"
      description="The TrustFlight technology stack is built to provide a secure, trusted, and verifiable interface to your aircraft's data. Combining modern software and user interfaces with powerful analytics, we are able to offer products that benefit our customers through easy to use, but powerful, interfaces."
    />
      <PageHeader
        header="Technology"
        subHeader="The TrustFlight technology stack is built to provide a secure, trusted, and verifiable interface to your aircraft's data. Combining modern software and user interfaces with powerful analytics, we are able to offer products that benefit our customers through easy to use, but powerful, interfaces."
      />
      <Container>
        <Row className="justify-content-md-center">
          <Col xl={10} lg={11}>
            {props.data.allMarkdownRemark.edges.map((edge, key) => (
              <Row className={styles.markdownCard}>
              
                <Col xl={4} md={5} className={styles.markdownIcon}>
                <ScrollAnimation animateOnce animateIn={key % 2 === 0 ? 'fadeIn' : 'fadeIn'} offset={0} delay={150}>
                  <img src={edge.node.frontmatter.icon.publicURL} alt="icon" />
                  </ScrollAnimation>
                </Col>
                <Col xl={8} md={7}>
                <ScrollAnimation animateOnce animateIn={key % 2 === 0 ? 'fadeIn' : 'fadeIn'} offset={0}>
                  <Title>{edge.node.frontmatter.title}</Title>
                  <Text dangerouslySetInnerHTML={{ __html: edge.node.html }} />
                  </ScrollAnimation>
                </Col>
              </Row>
            ))}
          </Col>
        </Row>
        <Row
          className={`justify-content-md-center ${styles.technologyCardTitle}`}
        >
          <Col md={10}>
          <ScrollAnimation animateOnce animateIn="fadeInDown" offset={0}>
            <Title>
              What makes our technology stack unique <br />
              in the aviation industry
            </Title>
            </ScrollAnimation>
          </Col>
          <Col lg={10} md={12} className={styles.techCardWrapper}>
            <TechnologyCard />
          </Col>
        </Row>
        <Started />
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query TechnologyQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___title] }
      filter: {
        frontmatter: { title: { in: ["Blockchain Technology", "Integration"] } }
      }
    ) {
      edges {
        node {
          frontmatter {
            title
            icon {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
              extension
              publicURL
            }
          }
          html
        }
      }
    }
  }
`;
